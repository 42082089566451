var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "looktTSubstances",
      attrs: {
        title: "附件上传",
        "mask-closable": false,
        draggable: false,
        width: "900",
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "div",
        { staticStyle: { width: "100%", height: "100%" } },
        [
          _c("Table", {
            ref: "table",
            staticStyle: { width: "100%", height: "auto" },
            attrs: {
              loading: _vm.loading,
              border: "",
              columns: _vm.columns,
              sortable: "custom",
              data: _vm.tableData,
              "max-height": "450",
            },
            scopedSlots: _vm._u([
              {
                key: "fileName",
                fn: function ({ row, index }) {
                  return [
                    row && row.id == 1
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              overflow: "hidden",
                              "text-overflow": "ellipsis",
                              "white-space": "nowrap",
                            },
                          },
                          [
                            _c(
                              "Tooltip",
                              {
                                staticStyle: {
                                  width: "100%",
                                  "z-index": "1288",
                                },
                                attrs: {
                                  transfer: true,
                                  "max-width": "300",
                                  content: _vm.pathInfo
                                    ? _vm.pathInfo
                                    : "还未上传",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      overflow: "hidden",
                                      "text-overflow": "ellipsis",
                                      "white-space": "nowrap",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.pathInfo ? _vm.pathInfo : "还未上传"
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    row && row.id == 2
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              overflow: "hidden",
                              "text-overflow": "ellipsis",
                              "white-space": "nowrap",
                            },
                          },
                          [
                            _c(
                              "Tooltip",
                              {
                                staticStyle: {
                                  width: "100%",
                                  "z-index": "1288",
                                },
                                attrs: {
                                  transfer: true,
                                  "max-width": "300",
                                  content: _vm.pathEvaluate
                                    ? _vm.pathEvaluate
                                    : "还未上传",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      overflow: "hidden",
                                      "text-overflow": "ellipsis",
                                      "white-space": "nowrap",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.pathEvaluate
                                          ? _vm.pathEvaluate
                                          : "还未上传"
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    row && row.id == 3
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              overflow: "hidden",
                              "text-overflow": "ellipsis",
                              "white-space": "nowrap",
                            },
                          },
                          [
                            _c(
                              "Tooltip",
                              {
                                staticStyle: {
                                  width: "100%",
                                  "z-index": "1288",
                                },
                                attrs: {
                                  transfer: true,
                                  "max-width": "300",
                                  content: _vm.pathLicense
                                    ? _vm.pathLicense
                                    : "还未上传",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      overflow: "hidden",
                                      "text-overflow": "ellipsis",
                                      "white-space": "nowrap",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.pathLicense
                                          ? _vm.pathLicense
                                          : "还未上传"
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    row && row.id == 4
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              overflow: "hidden",
                              "text-overflow": "ellipsis",
                              "white-space": "nowrap",
                            },
                          },
                          [
                            _c(
                              "Tooltip",
                              {
                                staticStyle: {
                                  width: "100%",
                                  "z-index": "1288",
                                },
                                attrs: {
                                  transfer: true,
                                  "max-width": "300",
                                  content: _vm.pathPersonData
                                    ? _vm.pathPersonData
                                    : "还未上传",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      overflow: "hidden",
                                      "text-overflow": "ellipsis",
                                      "white-space": "nowrap",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.pathPersonData
                                          ? _vm.pathPersonData
                                          : "还未上传"
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    row && row.id == 5
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              overflow: "hidden",
                              "text-overflow": "ellipsis",
                              "white-space": "nowrap",
                            },
                          },
                          [
                            _c(
                              "Tooltip",
                              {
                                staticStyle: {
                                  width: "100%",
                                  "z-index": "1288",
                                },
                                attrs: {
                                  transfer: true,
                                  "max-width": "300",
                                  content: _vm.path ? _vm.path : "还未上传",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      overflow: "hidden",
                                      "text-overflow": "ellipsis",
                                      "white-space": "nowrap",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.path ? _vm.path : "还未上传")
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "action",
                fn: function ({ row, index }) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "row",
                          "justify-content": "center",
                        },
                      },
                      [
                        _c(
                          "Upload",
                          {
                            ref: "upload",
                            staticStyle: {
                              width: "68px",
                              "margin-top": "0.8vh",
                              "margin-left": "5px",
                            },
                            attrs: {
                              type: "drag",
                              "show-upload-list": false,
                              name: "file",
                              accept: ".pdf, image/png, image/jpg, image/jpeg",
                              format: _vm.fileTypeArr,
                              "on-success":
                                row.id == 1
                                  ? _vm.uploadSuccessInfo
                                  : row.id == 2
                                  ? _vm.uploadSuccessEvaluate
                                  : row.id == 3
                                  ? _vm.uploadSuccessLicense
                                  : row.id == 4
                                  ? _vm.uploadSuccessPersonData
                                  : row.id == 5
                                  ? _vm.uploadSuccess
                                  : _vm.uploadSuccess,
                              data: _vm.data,
                              action:
                                row.id == 1
                                  ? _vm.apiInfo
                                  : row.id == 2
                                  ? _vm.apiEvaluate
                                  : row.id == 3
                                  ? _vm.apiLicense
                                  : row.id == 4
                                  ? _vm.apiPersonData
                                  : row.id == 5
                                  ? _vm.api
                                  : _vm.api,
                              headers: _vm.upHeaders,
                              "before-upload":
                                row.id == 1
                                  ? _vm.handleUploadInfo
                                  : row.id == 2
                                  ? _vm.handleUploadEvaluate
                                  : row.id == 3
                                  ? _vm.handleUploadLicense
                                  : row.id == 4
                                  ? _vm.handleUploadPersonData
                                  : row.id == 5
                                  ? _vm.handleUpload
                                  : _vm.handleUpload,
                            },
                          },
                          [
                            _c(
                              "Button",
                              {
                                staticStyle: { width: "100%", heigth: "100%" },
                                attrs: {
                                  type: "info",
                                  size: "small",
                                  icon: "ios-cloud-upload-outline",
                                },
                              },
                              [_vm._v("上传")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "Button",
                          {
                            staticStyle: {
                              width: "68px",
                              "margin-top": "0.8vh",
                              "margin-bottom": "0.8vh",
                              "margin-left": "5px",
                            },
                            attrs: {
                              type: "primary",
                              icon: "ios-eye-outline",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showPreviewFileMethod(row.id)
                              },
                            },
                          },
                          [_vm._v("预览")]
                        ),
                        _c(
                          "Button",
                          {
                            staticStyle: {
                              width: "100px",
                              "margin-top": "0.8vh",
                              "margin-bottom": "0.8vh",
                              "margin-left": "1px",
                            },
                            attrs: {
                              type: "primary",
                              icon: "md-arrow-round-down",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.downloadPreviewFileMethod(row.id)
                              },
                            },
                          },
                          [_vm._v("模板下载")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            color: "red",
            "font-weight": "bold",
            "margin-top": "10px",
          },
        },
        [
          _c("p", [_vm._v("提示：")]),
          _c("p", [
            _vm._v(
              "1.请按所下载的模板格式填写数据，并盖章后上传，模板格式禁止调整"
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
      _c("previewFile", {
        attrs: { url: _vm.url },
        model: {
          value: _vm.showPreviewFile,
          callback: function ($$v) {
            _vm.showPreviewFile = $$v
          },
          expression: "showPreviewFile",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }